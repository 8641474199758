import DOMPurify from 'dompurify';

const DEFAULT_SAFE_PATH = '#';
const VALID_PROTOCOLS = ['http:', 'https:'];

const getXSSProtectedUrl = (url: string) => {
  const sanitizedUrl = DOMPurify.sanitize(url, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: [],
    ALLOW_DATA_ATTR: false,
  });

  if (!sanitizedUrl) {
    return DEFAULT_SAFE_PATH;
  }

  try {
    const urlObj = new URL(sanitizedUrl);

    if (!VALID_PROTOCOLS.includes(urlObj.protocol)) {
      return DEFAULT_SAFE_PATH;
    }

    return `${urlObj.origin}${urlObj.pathname}`;
  } catch (e) {
    return DEFAULT_SAFE_PATH;
  }
};

export default getXSSProtectedUrl;
