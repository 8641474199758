import { gql, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { notify } from '@macpaw/macpaw-ui';
import { NotificationType } from '~/types';
import {
  ORGANIZATION_ADDED_TO_BETA,
  ORGANIZATION_ADDED_TO_DEMO,
  ORGANIZATION_REMOVED_FROM_BETA,
  ORGANIZATION_REMOVED_FROM_DEMO,
} from '../../constants';
import handleFormMutationError from '../../helpers/handleFormMutationError';
import { ORGANIZATION_DETAILS_QUERY } from '../queries';

export type UpdateOrganizationDashboardArgs = {
  organizationId: string;
  isCmmBusinessDashboardBeta?: boolean;
  isDemo?: boolean;
};

export const UPDATE_ORGANIZATION_DASHBOARD_MUTATION = gql`
  mutation UpdateOrganizationDashboard($organizationId: ID!, $isCmmBusinessDashboardBeta: Boolean, $isDemo: Boolean) {
    updateOrganizationDashboard(
      organizationId: $organizationId
      isCmmBusinessDashboardBeta: $isCmmBusinessDashboardBeta
      isDemo: $isDemo
    )
  }
`;

interface UpdateOrganizationDashboardVariables {
  organizationId: string;
  isCmmBusinessDashboardBeta: boolean;
}

export const useUpdateOrganizationDashboardMutation = () => {
  const [mutate, { loading }] = useMutation<void, UpdateOrganizationDashboardVariables>(
    UPDATE_ORGANIZATION_DASHBOARD_MUTATION,
  );

  const updateOrganizationBetaState = async (organizationId: string, isCmmBusinessDashboardBeta: boolean) => {
    try {
      await mutate({
        variables: {
          organizationId,
          isCmmBusinessDashboardBeta,
        },
        refetchQueries: [
          {
            query: ORGANIZATION_DETAILS_QUERY,
            variables: {
              organizationId,
            },
          },
        ],
      });

      notify(
        isCmmBusinessDashboardBeta ? ORGANIZATION_ADDED_TO_BETA : ORGANIZATION_REMOVED_FROM_BETA,
        NotificationType.Success,
      );
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);
    }

    return null;
  };

  const updateOrganizationDemoState = async (organizationId: string, isDemo: boolean) => {
    try {
      await mutate({
        variables: {
          organizationId,
          isDemo,
        },
        refetchQueries: [
          {
            query: ORGANIZATION_DETAILS_QUERY,
            variables: {
              organizationId,
            },
          },
        ],
      });

      notify(isDemo ? ORGANIZATION_ADDED_TO_DEMO : ORGANIZATION_REMOVED_FROM_DEMO, NotificationType.Success);
    } catch (error) {
      handleFormMutationError(error as ErrorResponse);
    }

    return null;
  };

  const addOrganizationToBeta = (organizationId: string) => updateOrganizationBetaState(organizationId, true);

  const removeOrganizationFromBeta = (organizationId: string) => updateOrganizationBetaState(organizationId, false);

  const addOrganizationToDemo = (organizationId: string) => updateOrganizationDemoState(organizationId, true);

  const removeOrganizationFromDemo = (organizationId: string) => updateOrganizationDemoState(organizationId, false);

  return {
    addOrganizationToBeta,
    removeOrganizationFromBeta,
    addOrganizationToDemo,
    removeOrganizationFromDemo,
    loading,
  };
};
