import { DropdownItem } from '@macpaw/macpaw-ui';
import { Maybe } from '~/types';
import { useUpdateOrganizationDashboardMutation } from '../../graphql/mutations';

interface OrganizationDemoStateSwitcherProps {
  organizationId: Maybe<string>;
  isDemo: Maybe<boolean>;
}

const OrganizationDemoStateSwitcherProps: React.FC<OrganizationDemoStateSwitcherProps> = ({
  organizationId,
  isDemo,
}) => {
  const { addOrganizationToDemo, removeOrganizationFromDemo } = useUpdateOrganizationDashboardMutation();

  return (
    <>
      {isDemo ? (
        <DropdownItem
          key="removeOrganizationFromBeta"
          onClick={() => removeOrganizationFromDemo(organizationId as string)}>
          Disable Demo Mode
        </DropdownItem>
      ) : (
        <DropdownItem key="addOrganizationToBeta" onClick={() => addOrganizationToDemo(organizationId as string)}>
          Enable Demo Mode
        </DropdownItem>
      )}
    </>
  );
};

export default OrganizationDemoStateSwitcherProps;
